import React, {useState, useEffect} from 'react'
import {MDBContainer, MDBRow, MDBCol, MDBInput} from 'mdbreact'

function Calculator() {

    let [weight, setWeight] = useState('')
    let [reps, setReps] = useState('')
    let [oneRepMax, setOneRepMax] = useState('')
    let [formula, setFormula] = useState('epley')

    useEffect(() => {
        switch(formula) {
            case 'epley':
                epleyFormula()
                return
            case 'bryzcki':
                    bryzckiFormula()
                    return
            default:
                return
        }
    })

    const epleyFormula = () => {
        setOneRepMax( (weight*(1+reps/30)).toFixed(2) )
    }
    const bryzckiFormula = () => {
        setOneRepMax( (weight*(36/(37-reps))).toFixed(2) )
    }

    return (
        <MDBContainer>
            <MDBRow className="mt-5">
                <MDBCol>
                    <MDBInput name="weight" type="number" value={weight} label="Weight Lifted" onChange={(e) => setWeight(e.target.value)} />
                    <MDBInput name="reps" type="number" value={reps} label="Reps Performed" onChange={(e) => setReps(e.target.value)} />
                    <h1>Your 1RM is: {oneRepMax == '' ? '...' : oneRepMax}</h1>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <select className="browser-default custom-select" onChange={(e) => setFormula(e.target.value)}>
                    <option value="epley">Epley Formula</option>
                    <option value="bryzcki">Brzycki Formula</option>
                </select>
            </MDBRow>
        </MDBContainer>
    )
}

export default Calculator
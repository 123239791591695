import React, {Fragment} from 'react';
import './App.css';
import Calculator from './Calculator'
import Nav from './Nav'

function App() {
	return (
		<Fragment>
			<Nav />
			<Calculator />
		</Fragment>
	)
}

export default App;
